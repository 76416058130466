/* eslint-disable react/prop-types */
import Head from 'next/head';
import { getDocumentByUid, renderSlices } from 'helpers/Prismic';
import globalProps from 'utils/globalProps';
import { useEffect, useContext, useState } from 'react';
import { StorefrontContext } from 'providers/storefront';
import Modal from 'react-modal';
import useScreenWidth from 'utils/useScreenWidth';
import Script from 'next/script';
import TagManager from 'react-gtm-module';

export default function Home({ homepageContent }) {
  const {
    body,
    seo_title: seoTitle,
    seo_description: seoDescription,
    og_description: ogDescription,
    og_image: ogImage,
    og_image_link: ogImageLink,
  } = homepageContent || {};

  const { checkLocation, setCheckLocation } = useContext(StorefrontContext);
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState(false);
  const [country, setCountry] = useState(false);

  useEffect(() => {
    fetch('/api/location')
      .then((res) => res.json())
      .then((data) => {
        const country = data.country;
        setCheckLocation(country);
        setCountry(country);
        if (country == 'SG') {
          openModal();
        }
      });
    setLocation(localStorage.getItem('userLocation'));
  }, [location]);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    localStorage.setItem('userLocation', country);
  };

  const widthSize = useScreenWidth();

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '15px 10px 10px 15px',
      width: widthSize <= 500 ? '90%' : '450px',
      height: widthSize <= 500 ? '27%' : '235px',
      background: '#242424',
      border: '1px solid #242424',
      overflow: 'hidden',
    },
  };

  const redirectTo = () => {
    window.location.href = 'https://www.aftershockpc.com/';
  };

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const tagManagerArgs = {
        gtmId: 'GTM-W8PZV3J',
      };

      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  return (
    <div>
      <Head>
        <meta
          name="google-site-verification"
          content="4BhCuDPzXYkH1lByV6vmMg28hd2uK4d9JaS1oFfELEk"
        />
        <link
          rel="preconnect dns-prefetch"
          href="https://triplewhale-pixel.web.app/"
          crossOrigin="true"
        />
        <link
          rel="preconnect dns-prefetch"
          href="https://api.config-security.com/"
          crossOrigin="true"
        />
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta property="og:description" content={ogDescription} />
        <meta
          property="og:image"
          content={
            ogImageLink?.url
              ? ogImageLink?.url
              : 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/ogImage.png?v=1713964496'
          }
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1024" />
      </Head>
      <Script
        src="/tripleWhale.js"
        dangerouslySetInnerHTML={{
          __html: 'TripleHeadless = "aftershockpcau.myshopify.com" ',
        }}
        strategy="lazyOnload"
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `!function(e,t,n,s,u,a)
				{e.twq ||
					((s = e.twq =
					function () {
						s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
					}),
					(s.version = '1.1'),
					(s.queue = []),
					(u = t.createElement(n)),
					(u.async = !0),
					(u.src = 'https://static.ads-twitter.com/uwt.js'),
					(a = t.getElementsByTagName(n)[0]),
					a.parentNode.insertBefore(u, a))}
				(window,document,'script');
				twq('config', 'oja69');
				twq('event', 'PageView');`,
        }}
        strategy="lazyOnload"
      />
      <main>
        {body &&
          body.map((block, index) =>
            renderSlices(block, block.slice_type, index),
          )}
      </main>
      {location == null && (
        <Modal
          style={customStyles}
          isOpen={open}
          contentLabel="Minimal Modal Example"
          onRequestClose={closeModal}
        >
          <button className="modal-btn-close" onClick={closeModal}>
            {'Close'}
          </button>
          <p className="modal-header">{'Welcome to Aftershock Australia'}</p>
          <div className="modal-location-container">
            <div style={{ marginTop: '8%' }}>
              <p className="location-prompt">
                You are in Aftershock Australia, do you want to redirect to
                Aftershock Singapore?
              </p>
            </div>
            <div>
              <button className="modal-btn-location" onClick={redirectTo}>
                Redirect to Singapore
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export async function getStaticProps({ preview = false, previewData }) {
  const {
    props: { global },
  } = await globalProps();

  const { ref } = previewData || {};

  const homePage = await getDocumentByUid('landing-page', 'homepage-new', ref);

  const homepageContent = homePage.data;

  return {
    props: {
      global,
      homepageContent,
      preview,
    },
    revalidate: 3600,
  };
}
